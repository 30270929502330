@import url("https://fonts.googleapis.com/css?family=Fira+Sans");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body,
ul,
li,
p,
a,
.nav-link,
label,
input,
div,
textarea {
  font-family: "Fira Sans", sans-serif;
  font-size: 18px !important;
  font-weight: 300 !important;
  color: rgb(96, 96, 96) !important;
}

.bdcart {
  background-color: rgb(129, 253, 255);
  color: rgb(255, 255, 255) !important;
}
.bg {
  color: rgb(255, 255, 255) !important;
}

a:hover,
.nav-link:hover,
label:hover,
input:hover,
textarea:hover {
  color: rgb(0, 0, 0) !important;
}

.bgbtn {
  background: rgb(244, 230, 170);
}

.h1 {
  font-family: "Fira Sans", sans-serif;
  font-size: 48px !important;
  font-weight: 200 !important;
  color: orange;
}

.h2 {
  font-family: "Fira Sans", sans-serif;
  font-size: 30px !important;
  font-weight: 300;
  color: orange;
}

.h3 {
  font-family: "Fira Sans", sans-serif;
  font-size: 22px !important;
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.834);
  z-index: 1;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: orange transparent orange transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
/*Style Login*/
.nosotros {
  display: flex;
  justify-content: right;
  align-items: right;
}
/*Style Whatsapp and Scroll to top buttons*/

.float,
.scroll-to-top-button {
  position: fixed;
  color: #fff;
  border-radius: 50px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.float:hover,
.scroll-to-top-button:hover {
  cursor: pointer;
}

.float {
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #0ec144;
}

.float:hover {
  background-color: #0c9635;
}

.scroll-to-top-button {
  width: 40px;
  height: 40px;
  bottom: 120px;
  right: 50px;
  background-color: orange;
  border: none;
}

.scroll-to-top-button:hover {
  background-color: rgb(255, 188, 64);
}

.my-float {
  margin-top: 16px;
}

/* General */
.logo {
  width: 250px auto !important;
  height: 50px !important;
}

.logo-shop {
  width: 250px auto !important;
  height: 50px !important;
  margin-bottom: 20px;
}

.text-warning {
  color: #ede861 !important;
}

.text-muted {
  color: #bcbcbc !important;
}

.text-success {
  color: #59ab59 !important;
}

.text-light {
  color: #cfd6e1 !important;
}

.btn {
  padding: 10px !important;
  border: none !important;
}

.bg-dark {
  background-color: #212934 !important;
}

.bg-light {
  background-color: #e9eef5 !important;
}

.bg-black {
  background-color: #1d242d !important;
}

.bg-success {
  background-color: orange !important;
}

.btn-success {
  background-color: #30fa84 !important;
  border-color: #0c470e !important;
  color: #000 !important;
}

.btn-login {
  background-color: #ffa500 !important;
  color: white !important;
}

.btn-login:hover {
  background-color: #ffbf47 !important;
  color: white !important;
}

.btn-lg {
  margin: 15px 0;
  padding: 10px 25px !important;
}

.btn-succes {
  background-color: #26e54c !important;
}

.btn-success:hover {
  cursor: pointer;
}

.cont {
  min-height: 75vh !important;
}

.pagination {
  justify-content: center;
}

.pagination button {
  border-radius: 5px !important;
  border: 1px solid #ced4da;
  padding: 8px 16px;
  text-decoration: none;
}

.pagination .active {
  border: 2px solid black !important;
}

.pagination button:not(.active):hover {
  background-color: #d6d6d6 !important;
}

/* .pagination .page-link:hover,
.pagination .page-link.active {
  background-color: rgb(102, 255, 0);
  color: #fff;
} */

/* .tablebg {
  background-image: linear-gradient(to right, 
                                  #ff873c4e, 
                                  #e6fc69a1, 
                                  #80f96d8e, 
                                  #7aedff9d);
  background: #fbf67343;
} */

table {
  border-radius: 10px !important;
  overflow: hidden !important;
  -webkit-box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.75);
}

.table-productos {
  background-color: #26e54c !important;
}

.table-descuentos {
  background-color: #ffca2c !important;
}

.table-categorias {
  background-color: #31d2f2 !important;
}

/* Nav */
#templatemo_nav_top {
  min-height: 40px;
}

#templatemo_nav_top * {
  font-size: 12px !important;
}

#templatemo_main_nav a {
  color: #212934;
}

#templatemo_main_nav a:hover {
  color: orange;
}

#templatemo_main_nav .navbar .nav-icon {
  margin-right: 20px;
}

@media screen and (max-width: 650px) {
  .navbar-toggler {
    width: 50px;
  }
}

/* Hero Carousel */
#template-mo-jassa-hero-carousel {
  background: #efefef !important;
  max-height: fit-content(650px);
}

.carousel-item {
  transform: translateX(100%) !important;
  transition: transform 0.5s !important;
}

.carousel-item.active {
  transform: translateX(0) !important;
}

.carousel-control-prev.active {
  transform: translateX(-100%);
}

.carousel-control-prev {
  transform: translateX(0);
  transition: transform 0.5s;
}

/* Accordion */
.templatemo-accordion a {
  color: #000;
}

.templatemo-accordion a:hover {
  color: #333d4a;
}

/* Shop */
.shop-top-menu a:hover {
  color: rgb(255, 213, 0) !important;
}

/* Product */

.col-sm-6.col-lg-3 {
  margin-bottom: 10px;
}

.single-publication {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product-image img {
  width: 100%;
  height: 100%;
}

.product-images img {
  width: 75%;
  height: 75%;
}

.product-wap {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.product-wap .product-color-dot.color-dot-red {
  background: #f71515;
}

.product-wap .product-color-dot.color-dot-blue {
  background: #6db4fe;
}

.product-wap .product-color-dot.color-dot-black {
  background: #000000;
}

.product-wap .product-color-dot.color-dot-light {
  background: #e0e0e0;
}

.product-wap .product-color-dot.color-dot-green {
  background: #0bff7e;
}

.card.product-wap .card .product-overlay {
  background: rgba(0, 0, 0, 0.2);
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
}

.card.product-wap:hover .card .product-overlay {
  visibility: visible;
  opacity: 1;
}

.card.product-wap a {
  color: rgb(96, 96, 96) !important;
}

#carousel-related-product .slick-slide:focus {
  outline: none !important;
}

#carousel-related-product .slick-dots li button:before {
  font-size: 15px;
  margin-top: 20px;
}
/* Para aumentar el tamaño de la imagen del logo */
#logo-img {
  width: 50px; /* Ajusta el valor a la dimensión que desees */
  height: auto; /* Ajusta la altura automáticamente para mantener la proporción */
}

/* Estilo boton Google*/
.btn-google {
  background-color: #fff; /* Color de fondo de Google */
  color: #333; /* Color del texto */
  border: 1px solid #ccc; /* Borde */
  transition: box-shadow 0.3s; /* Transición de la sombra al pasar el cursor */
}

.btn-google:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombras al pasar el cursor */
}
/* Estilos para el tamaño de la imagen del botón de Google */
.btn-google-logo {
  width: 32px; /* Ajusta este valor según tu preferencia */
  height: auto; /* Mantendrá la relación de aspecto */
  margin-right: 10px; /* Espacio entre la imagen y el texto del botón */
}

/* Brand */
.brand-img {
  filter: grayscale(100%);
  opacity: 0.5;
  transition: 0.5s;
}

.brand-img:hover {
  filter: grayscale(0%);
  opacity: 1;
}

/* Carousel Hero */
#template-mo-jassa-hero-carousel .carousel-indicators li {
  margin-bottom: -15px;
  background-color: orange;
}

#template-mo-jassa-hero-carousel .carousel-control-next i,
#template-mo-jassa-hero-carousel .carousel-control-prev i {
  color: orange !important;
  font-size: 2.8em !important;
}

/* Carousel Brand */
.tempaltemo-carousel .h1 {
  font-size: 0.5em !important;
  color: #000 !important;
}

/* Services */
.services-icon-wap {
  transition: 0.3s;
}

.services-icon-wap:hover,
.services-icon-wap:hover i {
  color: #fff;
}

.services-icon-wap:hover {
  background: rgb(255, 170, 0);
}

/*Products*/
.pt-50 {
  padding-top: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.section-header {
  margin-bottom: 60px;
  text-align: center;
}

.section-header i {
  color: #ff7700;
  font-size: 50px;
  display: inline-block;
  margin-bottom: 10px;
}

.section-header h2 {
  font-weight: bold;
  font-size: 34px;
  margin: 0;
}

.section-header p {
  max-width: 500px;
  margin: 20px auto 0;
}

.single-publication {
  border: 1px solid #f2eee2;
  margin-bottom: 0px;
  position: relative;
  overflow: hidden;
}

.single-publication figure {
  position: relative;
  margin: 0;
  text-align: center;
}

.single-publication figure > a {
  background-color: #fafafa;
  display: block;
}

.single-publication figure ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  right: -50px;
  top: 20px;
  transition: 0.6s;
  -webkit-transition: 0.6s;
}

.single-publication:hover figure ul {
  right: 15px;
}

.single-publication figure ul li a {
  display: inline-block;
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 15px;
  background: #ff7700;
  margin-bottom: 7px;
  border-radius: 50%;
  line-height: 35px;
  color: #fff;
}

.single-publication figure ul li a:hover {
  color: #fff;
  background: rgb(255, 157, 0);
}

.single-publication .publication-content {
  text-align: center;
  padding: 0px;
  background: #ffd48a3a;
}

.single-publication .publication-content .category {
  display: inline-block;
  font-size: 14px;
  color: #ff7300;
  font-weight: 600;
}

.single-publication .publication-content h3 {
  font-weight: 600;
  margin: 8px 0 10px;
  font-size: 20px;
}

.single-publication .publication-content h3 a {
  color: #1f2d30;
  font-size: 22px;
}

.single-publication .publication-content h3 a:hover {
  color: #ff7b00;
}

.single-publication .publication-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
}

.single-publication .publication-content ul li {
  display: inline-block;
  font-size: 18px;
  color: #fec42d;
}

.single-publication .publication-content .price {
  font-size: 18px;
  color: #ff7700;
}

.single-publication .publication-content .price span {
  color: #6f6f6f;
  text-decoration: line-through;
  padding-left: 5px;
  font-weight: 300;
}

.single-publication .add-to-cart {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  height: 105px;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-publication:hover .add-to-cart {
  visibility: visible;
  transform: scale(1);
  -webkit-transform: scale(1);
  opacity: 1;
}

.single-publication .add-to-cart .default-btn {
  margin-top: 28px;
  padding: 8px 25px;
  font-size: 14px;
}

.single-publication .category {
  margin: 0;
}

.single-publication .add-to-cart .default-btn {
  margin-top: 28px;
  padding: 8px 25px;
  font-size: 14px;
}

.default-btn {
  background-color: #ff7700;
  color: #fff;
  border: 1px solid #ff9500;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
}

.default-btn:hover {
  color: #fff;
  background-color: rgb(255, 191, 0);
}

figure img {
  width: 200px;
}

/*About us map*/
.about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about-container *,
.about-container *:before,
.about-container *:after {
  box-sizing: inherit;
}

.about-container .about-section {
  text-align: center;
}

.about-container h2 {
  margin: 20px 0 50px 0;
}

.about_main_container {
  display: flex;
  justify-content: center;
  margin: 50px 0;
  justify-content: center;
  gap: 1em;
}

.about-container .column {
  float: left;
  width: 30%;
}

.about-container .card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.about-container .about-section {
  padding: 50px;
  background-color: rgb(216, 216, 216);
  color: white;
  margin-bottom: 20px;
}

.about-container .container {
  padding: 0 30px;
}

.about-container .container::after,
.row::after {
  content: "";
  clear: both;
  display: table;
}

.about-container .title {
  color: grey;
}

.about-container .btn-success,
.about-container div {
  width: 100%;
}

.about-container .btn-warning,
.about-container div {
  width: 100%;
}
.about-container img {
  width: 75%;
  height: 75%;
  align-self: center;
  margin-bottom: 15px;
}

.about-container .about_img {
  width: 75%;
  height: 75%;
  align-self: center;
}

.about-container .about-github-container {
  display: flex;
  align-items: flex-end;
}

@media screen and (max-width: 650px) {
  .about-container .column {
    width: 100%;
    display: block;
  }
}

/* Contact map */
.contact-container {
  box-sizing: border-box;
  text-align: center;
  margin: 10px auto;
  height: 100%;
  /* background-color: aquamarine; */
}

.contact-wrap {
  background: #3f044b2c !important;
}

.contact-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 1%;
  background-color: #cdcdcd;
}

.contact-col1 {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.contact-col2 {
  width: 70%;
  display: flex;
  justify-content: center;
  margin: auto;
}

.form-container {
  /* background-color: #04AA6D; */
  border: 1px solid black;
  margin: 2px;
  margin-bottom: 30px;
}

.contact-container h1 {
  font-size: 36px;
  font-weight: bold;
}

.contact_main_container input[type="text"],
.contact_main_container input[type="email"],
.contact_main_container textarea,
.contact_main_container label:not([for="name"]):not([for="email"]) {
  margin-top: 30px;
}

.contact-container textarea {
  line-height: 1.5;
  height: 10em;
}

#contact-submit {
  margin-top: 30px;
}

/* .contact-container button {
  background-color: orange;
  color: white;
  padding: 12px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.contact-container button:hover {
  background-color: #ffaa00;
} */
/*Boton de pago Whatsapp*/

.custom-buttonw {
  color: rgba(248, 248, 250, 0.904); /* Color de texto por defecto */
  /*  background-color: #008000; Color de fondo por defecto */
  background-color: #888888;
  transition: color 0.3s ease;
  border-radius: 5px;
  height: 45px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Transición suave para el color de texto */
  outline: none;
  border-color: transparent;
  transition: all 0.5s ease-in-out;
}

.custom-buttonw:hover {
  color: rgb(15, 15, 15); /* Color de texto al pasar el ratón por encima */
  border: 1px solid white;
  font-size: 20px;
}
/*boton pago*/
.btnpago {
  color: white;
  background-color: #888888;
  border-radius: 5px;
  height: 45px;
  border-color: transparent;
  transition: all 0.5s ease-in-out;
}

.btnpago:hover {
  color: rgb(15, 15, 15); /* Color de texto al pasar el ratón por encima */
  border: 1px solid white;
  font-size: 20px;
}

/*TARJETA CARRITO*/
.tarjetach {
  background: rgb(238, 234, 231);
  background: radial-gradient(
    circle,
    rgba(238, 234, 231, 1) 45%,
    rgba(226, 214, 22, 1) 100%
  );
}

.contact-container form {
  border-radius: 5px;
  padding: 10px;
}

.contact-container iframe {
  border-radius: 5px;
}

@media screen and (max-width: 600px) {
  .contact-container,
  button,
  .contact-col1,
  .contact-col2,
  .contact-row {
    width: 100%;
    margin-top: 0;
    display: block;
  }
}
/*link gt*/
.textgt:hover {
  color: #f6b132;
}
/* Dashboard */
.dashboard-container {
  padding-top: 40px;
  width: 100%;
  text-align: center;
}

.dashboard-button {
  margin-top: 5px;
}

/* Login */
.login-page {
  background-color: #f7f9fb;
  font-size: 14px;
}

.login-page .brand {
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 50%;
  margin: 40px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  position: relative;
}

.login-page .brand img {
  width: 100%;
}

.login-page .card-wrapper {
  width: 400px;
}

.login-page .card {
  border-color: transparent;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.login-page .card.fat {
  padding: 10px;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: #f0f0f0;
}

.login-page .card .card-title {
  margin-bottom: 30px;
  text-align: center;
}

.login-page .form-control {
  border-width: 2.3px;
}

.login-page .form-group label {
  width: 100%;
}

.login-page .btn.btn-block {
  padding: 12px 10px;
}

.login-page .custom-control {
  display: flex;
  flex-flow: row;
  gap: 5px;
}

.login-alert {
  margin-top: 20px;
}

@media screen and (max-width: 425px) {
  .login-page .card-wrapper {
    width: 90%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 320px) {
  .login-page .card.fat {
    padding: 0;
  }

  .login-page .card.fat .card-body {
    padding: 15px;
  }
}

/*Gestión de productos*/
.data-table-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.data-table-header select,
.data-table-header input[type="text"] {
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.data-table-header select:hover {
  cursor: pointer;
}

/* Footer */
#tempaltemo_footer a {
  color: #dcdde1;
}

#tempaltemo_footer a:hover {
  color: rgb(255, 179, 0);
}

#tempaltemo_footer ul.footer-link-list li {
  padding-top: 10px;
}

#tempaltemo_footer ul.footer-icons li {
  width: 2.6em;
  height: 2.6em;
  line-height: 2.6em;
}

#tempaltemo_footer ul.footer-icons li:hover {
  background-color: #cfd6e1;
  transition: 0.5s;
}

#tempaltemo_footer ul.footer-icons li:hover i {
  color: #212934;
  transition: 0.5s;
}

#tempaltemo_footer .border-light {
  border-color: #2d343f !important;
}

/*
  // Extra small devices (portrait phones, less than 576px)
  // No media query since this is the default in Bootstrap
  */
/* Small devices (landscape phones, 576px and up)*/
.product-wap .h3,
.product-wap li,
.product-wap i,
.product-wap p {
  font-size: 12px !important;
}

.product-wap .product-color-dot {
  width: 6px;
  height: 6px;
}

.btn_search {
  background-color: #f6b132 !important;
}

@media (min-width: 576px) {
  .tempaltemo-carousel .h1 {
    font-size: 1em !important;
  }
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  #templatemo_main_nav .navbar-nav {
    max-width: 450px;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  #templatemo_main_nav .navbar-nav {
    max-width: 550px;
  }

  #template-mo-jassa-hero-carousel .carousel-item {
    min-height: 30rem !important;
  }

  .product-wap .h3,
  .product-wap li,
  .product-wap i,
  .product-wap p {
    font-size: 18px !important;
  }

  .product-wap .product-color-dot {
    width: 12px;
    height: 12px;
  }
}

.ProductDetails {
  background-color: rgb(255, 255, 255) !important;
  color: rgb(0, 255, 204) !important;
  display: flex !important;
  flex-direction: row !important;
  font-size: 20px;
}

.DetailsTitle {
  padding-top: var(--title-padding-top);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DetailsImg {
  padding-top: 100px;
  margin-bottom: 10px;
  width: 100%;
}

.backBtnContainer {
  text-align: center;
}

.ReactImageMagnify {
  display: flex !important;
  justify-content: center !important;
}

.ReactImageMagnify__SmallImage {
  width: 100% !important;
}

.ReactImageMagnify__EnlargedImage {
  z-index: 9999 !important;
  border-radius: 50% !important;
  overflow: hidden !important;
}

.ReactImageMagnify__EnlargedImageContainer {
  border-radius: 50% !important;
}

.nav-tabs .nav-item .nav-link {
  background-color: #fcd9a199;
  color: rgba(0, 0, 0, 0.737) !important;
}

.nav-tabs .nav-item .nav-link.active {
  color: rgb(255, 132, 0) !important;
}

.tab-content {
  border: 1px solid rgb(103, 255, 194) !important;
  border-top: transparent !important;
  padding: 15px !important;
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}
