.floating {
  animation: float 6s ease-in-out infinite;
}
/*
.floating-scroll {
	animation: float 6s ease-in-out infinite;
  }
*/
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
